import React from "react";
import Header from "./Header/Header";

function ErrorLoading({message}) {
  const style = {
    width: "500px",
    height: "100px",
    position: "fixed",
    top: "50px",
    bottom: "0",
    left: "0",
    right: "0",
    margin: "auto",
    fontSize: "24px",
    color: "#154273",
  };
  return (
    <div className="App ui js-app">
      <Header />
      <div style={style}>{message}</div>
    </div>
  );
}

export default ErrorLoading;
import React from "react";
import ErrorLoading from "./ErrorLoading";
import PageLoading from "./PageLoading";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import LatestMeasurementSummary from "./Body/LatestMeasurementSummary/LatestMeasurementSummary"
import ChartArea from "./Body/ChartArea/ChartArea"

import moment from "moment";
import createStrictContext from "../utils/createStrictContext";

const AppContext = createStrictContext();
const AppContextProvider = AppContext[0];
export const useAppContext = AppContext[1];

function postProcessRender(props) {
    const lengthOfFirstSeries = props.data.series[0].data.length;
    let latestMeasurement;
    let latestMeasurementToRender;
    let latestMeasurementDateTimeFormatted;
    if (lengthOfFirstSeries !== 0) {
      latestMeasurement = props.data.series[0].data[lengthOfFirstSeries - 1];
      latestMeasurementToRender =
        "" + latestMeasurement.value + props.data.series[0].unit;
      latestMeasurementDateTimeFormatted = moment(
        latestMeasurement.dateTime
      ).format("DD-MM-YYYY, HH:mm:ss");
    } else {
      latestMeasurementToRender = "0" + props.data.series[0].unit;
      latestMeasurementDateTimeFormatted = " - ";
    }
  
    if (props.isIframe) {
      const htmlEl = document.querySelector('html'); 
      htmlEl.style.overflow = 'auto';
      const bodyEl = document.querySelector('#v14app');; 
      bodyEl.style.overflow = 'auto';
    }
  
    return (
      <AppContextProvider value={props}>
        <div className="App ui js-app is-showing-details">
          {props.isIframe ? null : <Header location={props.location} kind={props.kind} /> }
          <div className="ui__details__body js-details__body">
            <div className="ui__details__body__item js-details__item is-complete">
              <LatestMeasurementSummary
                location={props.location}
                kind={props.kind}
                latestMeasurement={latestMeasurementToRender}
                latestDateTimeISO={latestMeasurementDateTimeFormatted}
              />
              <ChartArea
                data={props.data}
                kind={props.kind}
                location={props.location}
                getDataFromApi={props.getDataFromApi}
                dispatch={props.dispatch}
              />
            </div>
            <Footer />
          </div>
        </div>
      </AppContextProvider>
    );
  }

function AppInner ({
  page404,
  state,
  getDataFromApi,
  dispatch,
  navigatePage,
}) {
  if (page404) {
    return (
      <AppContextProvider value={{
        ...state,
        dispatch,
        navigatePage
      }}>
        <ErrorLoading message={"Ingediende URL is niet gevonden (404)"} />
      </AppContextProvider>
    );
  }

  if (state.status === "rejected") {
    return (
      <AppContextProvider value={{
        ...state,
        dispatch,
        navigatePage,
      }}>
        <ErrorLoading message={"De service is momenteel niet beschikbaar."} />
      </AppContextProvider>
    );
  }

  if (state.status === "idle" || state.status === "pending") {
    return <PageLoading />;
  }

  if (state.status === "resolved") {
    return null;
  }

  if (state.status === "refresh") {
    getDataFromApi(state.location, state.kind);
    return null;
  }

  /// ------ partial renders
  if (state.status === "refreshChart") {
    getDataFromApi(state.location, state.kind, false);
    return null;
  }

  /// -- all rest end up here
  return postProcessRender({
    ...state,
    dispatch,
    getDataFromApi,
    navigatePage,
  });
};

export default AppInner;

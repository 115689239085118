const actionTypes = {
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    START: "START",
    PROCESSED: "PROCESSED",
    REFRESH: "REFRESH",
    REFRESH_CHART: "REFRESH_CHART",
    OPEN_CLOSE_TABS: "OPEN_CLOSE_TABS",
    OPEN_CLOSE_LINK_POPUP: "OPEN_CLOSE_LINK_POPUP",
    OPEN_CLOSE_EMBED_POPUP: "OPEN_CLOSE_EMBED_POPUP"
}

export default actionTypes;
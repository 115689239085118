import React from "react";

function RefreshSvg({ svgClass, ariaHidden, id }) {
  return (
    <svg
      className={svgClass}
      id={id ?? "refresh"}
      aria-hidden={ariaHidden ?? "true"}
      viewBox="0 0 12 12"
    >
      <path
        d="M11.805 7.25a.134.134 0 0 1-.008.055c-.333 1.396-1.03 2.527-2.094 3.394C8.64 11.56 7.396 12 5.97 12a5.842 5.842 0 0 1-2.208-.43 5.964 5.964 0 0 1-1.903-1.226L.85 11.352a.48.48 0 0 1-.35.148.48.48 0 0 1-.352-.148A.48.48 0 0 1 0 11V7.5a.48.48 0 0 1 .148-.352A.48.48 0 0 1 .5 7H4a.48.48 0 0 1 .352.148c.1.1.148.217.148.352a.48.48 0 0 1-.148.352l-1.07 1.07c.37.344.788.61 1.257.797.464.182.95.28 1.46.28a3.9 3.9 0 0 0 1.95-.51 3.94 3.94 0 0 0 1.454-1.4c.058-.09.196-.394.414-.915.04-.12.12-.18.233-.18h1.5a.24.24 0 0 1 .176.073.24.24 0 0 1 .074.176zM12 1v3.5a.48.48 0 0 1-.148.352A.48.48 0 0 1 11.5 5H8a.48.48 0 0 1-.352-.148A.48.48 0 0 1 7.5 4.5a.48.48 0 0 1 .148-.352l1.08-1.078A3.882 3.882 0 0 0 6 2c-.698 0-1.35.17-1.953.508a3.94 3.94 0 0 0-1.453 1.398c-.058.09-.196.393-.414.914-.042.12-.12.18-.235.18H.39a.24.24 0 0 1-.175-.074.24.24 0 0 1-.074-.176v-.055C.48 3.3 1.187 2.168 2.25 1.3 3.318.435 4.568 0 6 0c.76 0 1.5.145 2.22.434.718.29 1.356.696 1.913 1.222L11.148.648A.48.48 0 0 1 11.5.5a.48.48 0 0 1 .352.148c.1.1.148.217.148.352z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}

export default RefreshSvg;

import React from "react";
import { v4 as uuidv4 } from "uuid";
import getOrderedLegendItems from "./orderLegendItems";

function Legend({totalSeries, limits, kind}) {
  
  var orderedLimits = getOrderedLegendItems(
    totalSeries,
    limits,
    kind
  );

  orderedLimits = orderedLimits.map((elem) => {
    elem.id = uuidv4();
    return elem;
  });

  const legendItemsHtml = orderedLimits.map((item) => {
    return (
      <li
        key={item.id}
        className="chart-legend__list__item chart-legend__list__item--series"
      >
        <svg
          className="chart-legend__list__item__icon"
          viewBox="0 0 20 4"
          width="20"
          height="4"
        >
          <span
            className="chart-legend__list__item__square"
            style={{ backgroundColor: item.color }}
          ></span>
          {item.isLimit ? (
            <path
              d="M1 1h20"
              stroke={item.color}
              strokeWidth="2"
              strokeLinecap="square"
              strokeDasharray="3,5"
              fill="none"
            ></path>
          ) : (
            <path
              d="M8.268 1H0v2h8.268a2 2 0 0 0 3.464 0H20V1h-8.268a2 2 0 0 0-3.464 0z"
              fill={item.color}
              fillRule="evenodd"
            ></path>
          )}
        </svg>
        <span className="chart-legend__list__item__label">{item.label}</span>
      </li>
    );
  });

  return (
    <div className="details__chart__legend js-details__chart__legend">
      <div className="chart-legend" aria-label="Legenda">
        <ul className="chart-legend__list">
          <span className="chart-legend__list__item__label"></span>
          {legendItemsHtml}
        </ul>
      </div>
    </div>
  );
}

export default Legend;

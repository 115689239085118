import React from "react";
import Refresh from "./Refresh/Refresh";
import InsluitenDelen from "./InsluitenDelen/InsluitenDelen";

function ActionsTab() {
  return (
    <div className="details__actions__buttons">
      <ul className="chart-actions">
        <Refresh />
        <InsluitenDelen />
      </ul>
    </div>
  );
}

export default ActionsTab;

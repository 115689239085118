import React from "react";
import ObservationKindButton from "./ObservationKindButton";
import { useAppContext } from "../../../../AppInner";
import kinds from "../../../../../utils/enums/kinds";

function ObservationKindTab() {
  const { disallowKinds } = useAppContext();

  function IncludeIfNotDisallowed(kind, index) {
    return !disallowKinds.includes(kind) ? (
      <ObservationKindButton key={index} containedKind={kind} />
    ) : null;
  }

  return (
    <div className="details__actions__view js-details__actions__view">
      <ul className="primary-tabs">
        {Object.values(kinds).map((kind, index) =>
          IncludeIfNotDisallowed(kind, index)
        )}
      </ul>
    </div>
  );
}

export default ObservationKindTab;

import React, { useRef, useEffect, useState } from "react";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import moment from "moment";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import { colorRgbaToHex } from "../../../../utils/color-utils"
import equal from 'deep-equal';

HC_more(Highcharts);
Highcharts.setOptions({
  time: {
    useUTC: false, // not assignable to type GlobalOptions
  },
  lang: {
    decimalPoint: ",",
    months: [
      "januari",
      "februari",
      "maart",
      "april",
      "mei",
      "juni",
      "juli",
      "augustus",
      "september",
      "oktober",
      "november",
      "december",
    ],
    shortMonths: [
      "jan",
      "feb",
      "mrt",
      "apr",
      "mei",
      "jun",
      "jul",
      "aug",
      "sep",
      "okt",
      "nov",
      "dec",
    ],
    thousandsSep: ".",
    weekdays: [
      "zondag",
      "maandag",
      "dinsdag",
      "woensdag",
      "donderdag",
      "vrijdag",
      "zaterdag",
    ],
  },
});

function setOptions(totalSeries, t0, limits) {
  limits.sort((a, b) => b.to - b.from - (a.to - a.from)); 
  const options = {
    title: undefined,
    subtitle: undefined,
    credits: {
      enabled: false,
    },
    chart: {
      animation: false,
      // renderTo: $chartSelector,
      height: 450,
      spacingRight: 0,
      spacingLeft: 0,
      spacingBottom: 20,
      events: {
        render: function () {
          const rects = document.querySelectorAll('.customPlotBandBorder');
          for (let i = 0; i < rects.length; i += 1) {
            const $parent = rects[i].parentNode;
            if ($parent) {
              $parent.removeChild(rects[i]);
              this.yAxis[0].removePlotLine(`customPlotLine-${i}`);
              this.yAxis[0].removePlotBand(`customPlotBand-${i}`);
            }
          }

          var extremesY = this.yAxis[0].getExtremes();
          var extremesX = this.xAxis[0].getExtremes();

          limits.forEach((limit, i) => {
            
            let { from } = limit;

            if (from === null) {
              from = extremesY.min;
            }
      
            let { to } = limit;
            if (to === null) {
              to = extremesY.max;
            }
      
            const limitSoftcolor = colorRgbaToHex(
              limit.softColorInRgba.red,
              limit.softColorInRgba.green,
              limit.softColorInRgba.blue,
              limit.softColorInRgba.opacity
            );
      
            // add plot band for each limit
            const band = {
              from,
              to,
              color: limitSoftcolor,
              zIndex: 0,
              id: `customPlotBand-${i}`,
            };
      
            this.yAxis[0].addPlotBand(band);
      
            let lineValue = limit.from;
            if (lineValue === null) {
              lineValue = limit.to;
            }
      
            const limitColor = colorRgbaToHex(
              limit.colorInRgba.red,
              limit.colorInRgba.green,
              limit.colorInRgba.blue,
              limit.colorInRgba.opacity
            );
      
            // add plot line horizontal
            const options = {
              dashStyle: "Dash",
              value: lineValue,
              color: limitColor,
              width: 2,
              zIndex: 1,
              id: `customPlotLine-${i}`,
            };
      
            // don't add for normal line
            if (!limit.isNormal) {
              this.yAxis[0].addPlotLine(options);
            }
      
            // add plotband border at the right hand side of the chart
            let top = +to;
            let bottom = +from;
      
            // if top point is lower than bottom of chart, don't draw
            if (top < extremesY.min) {
              return;
            }
      
            // if bottom point is lower than bottom point, take that value
            if (bottom < extremesY.min) {
              bottom = extremesY.min;
            }
      
            // if bottom point is higher than top of chart, don't draw
            if (bottom > extremesY.max) {
              return;
            }
      
            // if top point is higher than top top of chart, take that value
            if (top > extremesY.max) {
              top = extremesY.max;
            }
      
            const x1 = this.xAxis[0].toPixels(extremesX.max, false) - 3;
            const x2 = this.xAxis[0].toPixels(extremesX.max, false);
            const y1 = this.yAxis[0].toPixels(bottom, false);
            const y2 = this.yAxis[0].toPixels(top, false);
      
            this.renderer
              .rect(x1, y2, x2 - x1, y1 - y2)
              .attr({
                class: "customPlotBandBorder",
                fill: limitColor,
                zIndex: 3,
              })
              .add();
          });
        }
      }
    },
    yAxis: {
      title: {
        text: totalSeries[0].unit,
      },
      gridZIndex: 10,
      gridLineColor: "#bbbbbb",
      gridLineWidth: 0.5,
    },
    xAxis: {
      crosshair: {
        color: "#a6a6a6",
        dashStyle: "Solid",
        snap: false,
        width: 1,
        zIndex: 1,
      },
      startOnTick: false,
      endOnTick: false,
      type: "datetime",
      tickmarkPlacement: "between",
      dateTimeLabelFormats: {
        second: "%e. %b %H:%M",
        minute: "%e. %b %H:%M",
        hour: "%e. %b %H:%M",
        day: "%e. %b",
        week: "%e. %b",
        month: "%b %y",
        year: "%Y",
      },
      gridLineColor: "#bbbbbb",
      gridZIndex: 10,
      gridLineWidth: 0.5,
      gridLineDashStyle: "ShortDash",
      tickInterval: 24 * 3600 * 1000,
      tickWidth: 1,
      title: {
        text: "Tijd",
      },
      plotLines: [
        {
          color: "#a6a6a6",
          dashStyle: "Solid",
          value: moment(t0).valueOf(),
          width: 2,
          zIndex: 5,
        },
      ],
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      // crosshairs: true,
      shared: true,
      followPointer: true,
      backgroundColor: undefined,
      borderWidth: 0,
      borderRadius: 0,
      useHTML: true,
      shadow: false,
      valueSuffix: totalSeries[0].unit,
    },
    series: totalSeries,
  };

  return options;
}

function Chart({ limits, totalSeries, t0 }) {
  const [areLimitsVisible, setAreLimitsVisible] = useState(false);

  const chartComponentRef = useRef(null);

  useEffect(() => {
    const chart = chartComponentRef.current.chart;
    let extremesY = chart.yAxis[0].getExtremes();
    const seriesMinMax = getSeriesMinMax(totalSeries);
    const limitsMinMax = getLimitsMinMax(limits);    
    if (!areLimitsVisible) {
        chart.yAxis[0].setExtremes(extremesY.dataMin, extremesY.dataMax, true, false);
    } else {
      const min = Math.min(limitsMinMax.min, seriesMinMax.min)
      const max = Math.max(limitsMinMax.max, seriesMinMax.max)
      chart.yAxis[0].setExtremes(min, max);
    }

    return () => {};
  }, [limits, totalSeries, areLimitsVisible]);

  return (
    <>
      <ErrorMessage />
      <div className="spinner" style={{display:"none"}}></div>
      <div className="details__chart__chart">
        <p className="accessibility">
          Voor screenreaders wordt het gebruik van de tabelweergave aangeraden.
        </p>
        <div
          className="js-details__chart"
          data-highcharts-chart="0"
          style={{ overflow: "hidden" }}
        >
          <HighchartsReact
            highcharts={Highcharts}
            options={setOptions(totalSeries, t0, limits)}
            ref={chartComponentRef}
            // allowChartUpdate={true}
            immutable={true}
            // updateArgs = { [true, true, false] }
          />
        </div>
        <label
          className="details__chart__limits js-details__chart__limits"
          style={{ display: "block" }}
        >
          <input
            className="details__chart__limits__input js-details__chart__limits__input"
            type="checkbox"
            name="details__chart-limits"
            onChange={
              (ev) => { 
              setAreLimitsVisible(ev.target.checked);
          }}
          />
          Grenswaarden weergeven
        </label>
      </div>
    </>
  );
}

function getSeriesMinMax(series) {
  let min = 0;
  let max = 0;

  series.forEach((collection) => {
    if (!collection.data) return;
    collection.data.forEach((row, index) => {
      if (!row) return;
      const seriesLineDataOptions = row;
      if (!seriesLineDataOptions.y) return;
      if ((!min || seriesLineDataOptions.y < min) && seriesLineDataOptions.y) {
        min = seriesLineDataOptions.y;
      }

      if (!max || seriesLineDataOptions.y > max) {
        max = seriesLineDataOptions.y;
      }
    });
  });
  return {
    min: +min,
    max: +max,
  };
}

function getLimitsMinMax(limits) {
  return {
    min: limits.filter(x => x.label.includes("Verlaagd"))[0].to,
    max: limits.filter(x => x.label.includes("Extre"))[0].from,
  };
}
const areEqual = (prevProps, nextProps) => {
  const indeedEqual = (
    equal(prevProps.limits, nextProps.limits) 
    && equal(prevProps.totalSeries, nextProps.totalSeries) 
  );
  return indeedEqual;
}

export default React.memo(Chart, areEqual);

import React from "react";

function ExportSvg({ svgClass, ariaHidden, id }) {
  return (
    <svg
      className={svgClass}
      id={id ?? "export"}
      aria-hidden={ariaHidden ?? "true"}
      viewBox="0 0 14 14"
    >
      <path d="M11 9.5c-.51 0-.98.173-1.36.46L4.98 7.282a2.24 2.24 0 0 0-.474-1.687c.022-.02.078-.023.13.01.094.06.445.318.83.85l4.18-2.412c.376.286.844.457 1.354.457a2.25 2.25 0 1 0-2.23-1.972L4.114 5.215c-.38-.29-.85-.465-1.365-.465A2.25 2.25 0 1 0 4.1 8.796l4.667 2.68A2.25 2.25 0 1 0 11 9.5z"></path>
    </svg>
  );
}

export default ExportSvg;

import React from "react";

function InfoSvg({ svgClass, ariaHidden, id }) {
  return (
    <svg
      className={svgClass}
      id={id ?? "info"}
      viewBox="0 0 20 20"
      aria-hidden={ariaHidden ?? "true"}
    >
      <path
        d="M10 0C4.478 0 0 4.478 0 10c0 5.523 4.478 10 10 10 5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10zm0 17.5c-4.487 0-7.5-3.012-7.5-7.5 0-4.487 3.013-7.5 7.5-7.5 4.488 0 7.5 3.013 7.5 7.5 0 4.488-3.012 7.5-7.5 7.5zM8.75 5h2.5v2.5h-2.5V5zM7.5 15v-1.25h1.25V10H7.5V8.75h3.75v5h1.25V15h-5z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}

export default InfoSvg;

import kinds from '../utils/enums/kinds';
import locations from '../utils/enums/locations';
import actionTypes from '../utils/enums/actionTypes';

export default function apiReducer(state, action) {
  let newState = {};

  switch (action.type) {
    case actionTypes.ERROR: {
      newState = {
        ...state,
        status: "rejected",
        error: action.payload.error,
      }
      console.log(actionTypes.ERROR, newState)
      break;
    }
    case actionTypes.SUCCESS: {
      newState = {
        ...state,
        status: "resolved",
        location: action.payload.location,
        kind: action.payload.kind,
        data: JSON.parse(JSON.stringify(action.payload.data))
      };
      console.log(actionTypes.SUCCESS, newState)
      break;
    }
    case actionTypes.PROCESSED: {
      newState = {
        ...state,
        status: "processed",
        location: action.payload.location,
        kind: action.payload.kind,
        data: JSON.parse(JSON.stringify(action.payload.newData))
      };
      console.log(actionTypes.PROCESSED, newState)
      break;
    }
    case actionTypes.START: {
      newState = {
        ...state,
        status: "pending",
        insluitenDelenActive: false,
        linkPopupActive: false,
        embedPopupActive: false,
        isGrenswaardenActive: false,
      };
      console.log(actionTypes.START, newState)
      break;
    }
    case actionTypes.REFRESH: {
      newState = {
        ...state,
        location: action.payload.location,
        kind: action.payload.kind,
        insluitenDelenActive: false,
        linkPopupActive: false,
        embedPopupActive: false,
        isGrenswaardenActive: false,
        status: "refresh",
      };
      console.log(actionTypes.REFRESH, newState)
      break;
    }
    case actionTypes.REFRESH_CHART: {
      let disallowKinds = [];
      let kind = action.payload.kind;

      if (action.payload.location === locations.SintPieter) {
        disallowKinds = [kinds.Waterhoogte];
        kind = kinds.Waterafvoer; // ONLY option for Sint Pieter
      }

      newState = {
        ...state,
        location: action.payload.location,
        kind: kind,
        insluitenDelenActive: false,
        linkPopupActive: false,
        embedPopupActive: false,
        isGrenswaardenActive: false,
        disallowKinds: disallowKinds,
        status: "refreshChart",
      };
      console.log(actionTypes.REFRESH_CHART, newState)
      break;
    }
    case actionTypes.OPEN_CLOSE_TABS: {
      newState = {
        ...state,
        insluitenDelenActive: action.payload.insluitenDelenActive,
        status: "openTabs",
      };
      console.log(actionTypes.OPEN_CLOSE_TABS, newState)
      break;
    }
    case actionTypes.OPEN_CLOSE_LINK_POPUP: {
      newState = {
        ...state,
        linkPopupActive: action.payload.linkPopupActive,
        status: "openLinkPopup",
      };
      console.log(actionTypes.OPEN_CLOSE_LINK_POPUP, newState)
      break;
    }
    case actionTypes.OPEN_CLOSE_EMBED_POPUP: {
      newState = {
        ...state,
        embedPopupActive: action.payload.embedPopupActive,
        status: "openEmbedPopup",
      };
      console.log(actionTypes.OPEN_CLOSE_EMBED_POPUP, newState)
      break;
    }
    default: {
      newState = state;
      console.error(`Unhandled action type: ${action.type}`);
      break;
    }
  }
  return newState;
}
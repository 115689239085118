import React, {memo} from "react";
import Info from "../../assets/SVGs/InfoSvg";

function Footer() {
  return (
    <ul className="list list--icon">
      <li className="list__item">
        <a
          className="list__item__link"
          href="https://www.rijkswaterstaat.nl/lobith"
          data-href="https://www.rijkswaterstaat.nl/lobith"
          target="_blank"
          rel="noreferrer"
        >
          <Info
            svgClass={"list__item__icon list__item__icon--info"}
          />
          <span className="list__item__label">
            Meer informatie{" "}
            <span className="accessibility"> (meer informatie)</span>
          </span>
        </a>
      </li>
    </ul>
  );
}

export default memo(Footer);

import React from "react";
import LinkSvg from "../../../../../../assets/SVGs/LinkSvg"
import CommonPopupBase from "./CommonPopupBase"
import { useAppContext } from "../../../../../AppInner";
import actionTypes from "../../../../../../utils/enums/actionTypes";
import { kebabize } from "../../../../../../utils/string-utils"

function LinkPopup() {
  const {dispatch, kind, location, linkPopupActive} = useAppContext();
  
    const LinkPopUpClose = () => {
      dispatch({type: actionTypes.OPEN_CLOSE_TABS, payload: { kind, location, insluitenDelenActive: false }})
      dispatch({type: actionTypes.OPEN_CLOSE_LINK_POPUP, payload: { kind, location, linkPopupActive: false }})
  }
  
  return (
    <div
      className="chart-share js-details__share-link details__chart-share details__chart-share--link"
      data-type="share-link"
      style={{display: linkPopupActive ? "block" : "none"}}
    >
      <label
        className="chart-share__label"
        htmlFor="details-chart-0__share-link__input"
      >

        <LinkSvg svgClass={"chart-share__label__icon"} />
        Link
      </label>
      <CommonPopupBase clipboardText={`${window.location.protocol +"//" + window.location.host + "/"}${kebabize(location)}/${kebabize(kind)}`} onCloseHandler={LinkPopUpClose} />
    </div>
  );
}

export default LinkPopup;

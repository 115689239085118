import React from "react";
import Location from "../../../assets/SVGs/LocationSvg";

function LatestMeasurementSummary({location, kind, latestMeasurement, latestDateTimeISO}) {
  return (
    <>
      <h3 className="details__title">
        Verwachting {kind.trim().toLowerCase()} meetpunt {location}
      </h3>
      <p className="details__description">
        <Location svgClass="details__description__icon"/>
        <strong className="details__description__location">{location}</strong>
        <span className="details__description__latest">
          <span className="details__description__latest__title">
            Laatste meting:
          </span>
          <strong className="details__description__latest__data js-details__latest-date">
            {latestMeasurement}
          </strong>
          <span className="details__description__latest__time">
            &nbsp;op&nbsp;
            <span className="js-details__latest-time">
              {latestDateTimeISO}
            </span>
          </span>
        </span>
      </p>
    </>
  );
}

export default LatestMeasurementSummary;

/**
 * WARNING: This might break if string does not have a hexCode color form.
 * I do not want to throw exception however or return false codes since it will mess up the type system.
 * I want to refactor all hexCode values in the code base to RGB because Typescript can work with them whereas it (currently) cannot support hexCode template literals
 * So if we don't want to do runtime checks with js, we need to work with RGB values. And that means converting to the server hexCodes and checking to the API point
 * Only in the api folder do we really want runtime checks that throw exceptions
 */
// function colorHexToRGB(hexColor) {
//   const color = hexColor.substring(1, hexColor.length);
//   let red = 0;
//   let green = 0;
//   let blue = 0;
//   if (color.length === 3) {
//     red = parseInt(color[0], 16);
//     green = parseInt(color[1], 16);
//     blue = parseInt(color[2], 16);
//   } else {
//     red = parseInt(color[0] + color[1], 16);
//     green = parseInt(color[2] + color[3], 16);
//     blue = parseInt(color[4] + color[5], 16);
//   }

//   return { r: red, g: green, b: blue};
// }

/**
 * WARNING: This is something we shouldn't be using see above
 */
export function colorRGBToHex(red, green, blue){
  function formatHex(value) {
    if (value.length === 1) {
      return `0${value}`;
    }
    return value;
  }

  const hexRed = formatHex(red.toString(16));
  const hexGreen = formatHex(green.toString(16));
  const hexBlue = formatHex(blue.toString(16));

  return `#${hexRed}${hexGreen}${hexBlue}`;
}

export function colorRgbaToHex(
  red,
  green,
  blue,
  opacity
) {
  // eslint-disable-next-line no-bitwise
  const hex =
    // eslint-disable-next-line no-bitwise
    (red | (1 << 8)).toString(16).slice(1) +
    // eslint-disable-next-line no-bitwise
    (green | (1 << 8)).toString(16).slice(1) +
    // eslint-disable-next-line no-bitwise
    (blue | (1 << 8)).toString(16).slice(1);

  // multiply before convert to HEX
  // eslint-disable-next-line no-bitwise
  const a = ((opacity * 255) | (1 << 8)).toString(16).slice(1);

  return `#${hex}${a}`;
}

// function calculateTransparentColor(
//    foregroundColor,
//    backgroundColor,
//    opacity
//  ) {
//    const foregroundRGB = colorHexToRGB(foregroundColor);
//    const backgroundRGB = colorHexToRGB(backgroundColor);
//    const finalRed = Math.round(backgroundRGB.r * (1 - opacity) + foregroundRGB.r * opacity);
//    const finalGreen = Math.round(backgroundRGB.g * (1 - opacity) + foregroundRGB.g * opacity);
//    const finalBlue = Math.round(backgroundRGB.b * (1 - opacity) + foregroundRGB.b * opacity);
//    return colorRGBToHex(finalRed, finalGreen, finalBlue);
// }

import React from "react";
import { useAppContext } from "../AppInner";
import { Dropdown } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import locations from '../../utils/enums/locations'
import actionTypes from "../../utils/enums/actionTypes";
import kinds from '../../utils/enums/kinds'

function Header({location}) {
  const {kind:currentKind, location:currentLocation, navigatePage, dispatch} = useAppContext();

  const dropdownStyle = {
    backgroundColor: "#f3f3f3",
    borderColor: "#f3f3f3",
    color: "#154273",
    fontSize: "21px",
    top: "-10px",
    position: "relative",
  };

  const handleSelect=(e) => {
    if (location !== e) {
      let kindPayload;
      if (e === locations.SintPieter) {
        kindPayload = kinds.Waterafvoer
      } 
      else 
      {
        kindPayload = currentKind
      }

      navigatePage({kind: kindPayload, location: e})
      dispatch({ type: actionTypes.REFRESH_CHART, payload: {location: e, kind: currentKind }});
    }
  }

  const DropdownMenuItems = (locations) => {
    return locations.map(location => { return {location: location, index: uuidv4()}})
    .map(({location, index}) => <Dropdown.Item key={index} eventKey={location}>{location}</Dropdown.Item>);
  }
 
  return (
    <>
      <div className="ui__details__header">
        <div className="panel__topbar">
          <div className="panel__topbar__label js-details__title">
            <Dropdown 
            onSelect={handleSelect}
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                style={dropdownStyle}
              >
                {currentLocation}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {DropdownMenuItems(Object.values(locations))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;

import React from "react";

function CircledCrossSvg({ svgClass, ariaHidden, id }) {
  return (
    <svg
      className={svgClass}
      id={id ?? "circled-cross"}
      aria-hidden={ariaHidden ?? "true"}
      viewBox="0 0 20 20"
    >
      <path
        d="M12.058 10l3.643 3.643-2.055 2.057L10 12.057 6.357 15.7 4.3 13.643 7.943 10 4.3 6.357l2.057-2.06L10 7.94l3.643-3.642 2.058 2.06L12.06 10zM10 0C4.477 0 0 4.476 0 10c0 5.52 4.477 10 10 10 5.522 0 10-4.48 10-10 0-5.524-4.478-10-10-10z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}

export default CircledCrossSvg;

import {useCallback} from 'react'
import kinds from '../utils/enums/kinds';
import actionTypes from '../utils/enums/actionTypes';

function validate(data) {
  return true; // for the time being everything is valid
}

const waterafvoerMetadata = [
  {
    label: 'Gemeten afvoer',
    isLimit: false,
    zIndex: 100,
  },
  {
    label: 'Afvoer verwacht',
    isLimit: false,
    zIndex: 90,
  },
  {
    label: 'Modelverwachting mediaan',
    isLimit: false,
    zIndex: 7,
  },
  {
    label: 'Verlaagd (<1400m3/s)',
    isLimit: true,
  },
  {
    label: 'Normaal (>1400 m3/s)',
    isLimit: true,
  },
  {
    label: 'Mediaan meerjarig',
    isLimit: true,
    zIndex: 200,
  },
  {
    label: 'Licht verhoogd (>4400 m3/s)',
    isLimit: true,
  },
  {
    label: 'Verhoogd (>5400m3/s)',
    isLimit: true,
  },
  {
    label: 'Hoge afvoer (>8100 m3/s)',
    isLimit: true,
  },
  {
    label: 'Extreme afvoer (>11800 m3/s)',
    isLimit: true,
  },
];

const waterhoogteMetadata = [
  {
    label: 'Gemeten waterhoogte',
    isLimit: false,
    zIndex: 100,
  },
  {
    label: 'Waterhoogte verwacht',
    isLimit: false,
    zIndex: 90,
  },
  {
    label: 'Modelverwachting mediaan',
    isLimit: false,
    zIndex: 7,
  },
  {
    label: 'Overeengekomen lage rivierstand (< 739cm)',
    isLimit: true,
  },
  {
    label: 'Verlaagde waterstand (< 815cm)',
    isLimit: true,
  },
  {
    label: 'Normaal (> 815cm)',
    isLimit: true,
  },
  {
    label: 'Mediaan meerjarig',
    isLimit: true,
    zIndex: 200,
  },
  {
    label: 'Licht verhoogd (>1200cm)',
    isLimit: true,
  },
  {
    label: 'Verhoogd (1300 - 1500cm)',
    isLimit: true,
  },
  {
    label: 'Hoogwater (> 1500cm)',
    isLimit: true,
  },
  {
    label: 'Extreem hoogwater (> 1650cm)',
    isLimit: true,
  },
];

export default function useProcessResponse(dispatch) {
  return useCallback((data, location, kind, start = true) => {
    const isValid = validate(data);
    if (isValid) {
      data.series.forEach((serie) => {
        let metadata;
        if (kind === kinds.Waterafvoer) {
          metadata = waterafvoerMetadata.find(
            (metadataWaterafvoer) => metadataWaterafvoer.label === serie.name
          );
        } else {
          metadata = waterhoogteMetadata.find(
            (metadataWaterhoogte) => metadataWaterhoogte.label === serie.name
          );
        }
        if (metadata.zIndex) {
          serie.zIndex = metadata.zIndex;
        }
        serie.isLimit = false; // initial value
        if (metadata.isLimit) {
          serie.isLimit = metadata.isLimit;
        }
      });

      // data.extremesY = undefined;

      dispatch({
        type: actionTypes.PROCESSED,
        payload: { location: location, kind: kind, newData: data },
      });
      
    }

    // data is not valid - dispatch invalidData
  }, [dispatch]);
};

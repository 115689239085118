export const kebabize = (val) => {
  return val
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .replace(/_{1,}/g, "-")
    .toLowerCase();
};

export const pascalCase = (s) =>
  s.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });

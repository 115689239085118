import React from "react";
import EmbedSvg from "../../../../../../assets/SVGs/EmbedSvg";
import CommonPopupBase from "./CommonPopupBase";
import { useAppContext } from "../../../../../AppInner";
import actionTypes from "../../../../../../utils/enums/actionTypes";
import { kebabize } from "../../../../../../utils/string-utils"

function EmbedPopup() {
  const { dispatch, kind, location, embedPopupActive } = useAppContext();

  const EmbedPopUpClose = () => {
    dispatch({
      type: actionTypes.OPEN_CLOSE_TABS,
      payload: {
        kind: kind,
        location: location,
        insluitenDelenActive: false,
      },
    });
    dispatch({
      type: actionTypes.OPEN_CLOSE_EMBED_POPUP,
      payload: {
        kind: kind,
        location: location,
        embedPopupActive: false,
      },
    });
  };
  const clipboardText = `<iframe title="Verwachting waterhoogte meetpunt ${location}" aria-label="Grafiek van meetpunt ${location}" id="datawrapper-chart-bdqZJ" src="${`${window.location.protocol +"//" + window.location.host + "/"}${kebabize(location)}/${kebabize(kind)}#iframe`}" scrolling="no" frameborder="0" style="width: 0; min-width: 100% !important; border: none; overflow: auto; overflow: auto;  height: 750px;"></iframe><script type="text/javascript">window.addEventListener('message', function(event) {if (typeof event.data['datawrapper-height'] !== 'undefined') {var iframes = document.querySelectorAll('iframe');for (var chartId in event.data['datawrapper-height']) {for (var i = 0; i < iframes.length; i++) {if (iframes[i].contentWindow === event.source) {var iframe = iframes[i]iframe.style.height = event.data['datawrapper-height'][chartId] + 'px';}}}}});</script>`
  return (
    <div
      className="chart-share js-details__share-embed details__chart-share details__chart-share--embed"
      data-type="share-embed"
      style={{ display: embedPopupActive ? "block" : "none" }}
    >
      <label
        className="chart-share__label"
        htmlFor="details-chart-0__share-embed__input"
      >
        <EmbedSvg svgClass={"chart-share__label__icon"} />
        Insluiten
      </label>
      <CommonPopupBase
        clipboardText={clipboardText}
        // clipboardText={`<iframe width="100%" height="800px" src="${`${window.location.protocol +"//" + window.location.host + "/"}${kebabize(location)}/${kebabize(kind)}#iframe`}" frameborder="0"></iframe><script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.5/iframeResizer.min.js"></script><script>iFrameResize();</script>`}
        onCloseHandler={EmbedPopUpClose}
      />
    </div>
  );
}

export default EmbedPopup;

import moment from "moment";
import { colorRgbaToHex } from "../../../utils/color-utils";
import kinds from '../../../utils/enums/kinds';

export default function preprocessSeries(series) {
  series.forEach((serie) => {
    serie.marker = {
      enabled: true,
      symbol: "circle",
      radius: 0, // serie.isExtremen ? 3 : 0,
    };

    serie.color = colorRgbaToHex(
      serie.colorInRgba.red,
      serie.colorInRgba.green,
      serie.colorInRgba.blue,
      serie.colorInRgba.opacity
    );
    serie.turboThreshold = 0;

    serie.data.forEach((dataPoint) => {
      dataPoint.x = moment(dataPoint.dateTime).valueOf();
      dataPoint.y = dataPoint.value;
    });

    if (serie.isLimit) {
      serie.dashStyle = "Dash";
      serie.lineWidth = 2; // width
      serie.enableMouseTracking = false;
      serie.states = {
        inactive: {
          opacity: 1,
        },
      };
    }
  });
}

export function parseHighLowSeries(highLowSeries) {
  return highLowSeries.map((highLowSerie, index) => {
    highLowSerie.type = 'arearange';
    const { data } = highLowSerie;
    const newSerieData = (data).map((dataPoint) => [
      moment(dataPoint.dateTime).valueOf(),
      dataPoint.min,
      dataPoint.max,
    ]);

    return {
      measurementType: highLowSerie.measurementType,
      unit: highLowSerie.unit,
      colorInRgba : highLowSerie.colorInRgba,
      name: highLowSerie.name,
      data: newSerieData,
      color: colorRgbaToHex(
        highLowSerie.colorInRgba.red,
        highLowSerie.colorInRgba.green,
        highLowSerie.colorInRgba.blue,
        highLowSerie.colorInRgba.opacity
      ),
      type: 'arearange',
      fillOpacity: 0.3,
      zIndex: 4 + index,
      lineWidth: 0,
      marker: {
        enabled: false,
      },
    };
  });
}

export function sortTotalSeries(
  unsortedSeries,
  kind
) {
  const orders = {
    // refactor to enums if possible
    waterhoogte: [
      'Waterhoogte verwacht',
      'Modelverwachting mediaan',
      'Mediaan meerjarig',
      '33-66% band',
      '10-90% band',
      'Gemeten waterhoogte',
    ],
    afvoer: [
      'Afvoer verwacht',
      'Modelverwachting mediaan',
      'Mediaan meerjarig',
      '33-66% band',
      '10-90% band',
      'Gemeten afvoer',
    ],
  };
  let order;
  if (kind === kinds.Waterafvoer) {
    order = orders.afvoer;
  } else {
    order = orders.waterhoogte;
  }

  return unsortedSeries.sort((serieA, serieB) => order.indexOf(serieA.name) - order.indexOf(serieB.name));
}

export function filterTotalSeriesDataHistory(series) {
  const currentDateTimeX = new Date().getTime().toString();

  series.forEach((serie) => {
    if (serie.name === 'Gemeten waterhoogte' || serie.name === 'Gemeten afvoer' || (serie).isLimit) {
      return;
    }

    if (serie.type === 'arearange') {
      const { data } = serie;
      serie.data = (data).filter((dataPoint) => dataPoint[0].toString() > currentDateTimeX); // It seems we compare with strings in order to "truncate" milliseconds

      return;
    }

    const { data } = serie;
    serie.data = data.filter((dataPoint) => dataPoint.x.toString() > currentDateTimeX);
  });
}

import React from "react";
import { useAppContext } from "../../../../AppInner";
import actionTypes from "../../../../../utils/enums/actionTypes";

function ObservationKindButton({ containedKind }) {
  const {location, kind, navigatePage, dispatch} = useAppContext();

  const handleClick = (event) => {
    const payload = { kind: event.target.textContent, location: location };
    if (containedKind !== kind) {
      navigatePage(payload);
      dispatch({
        type: actionTypes.REFRESH_CHART,
        payload: payload,
      });
      return;
    }
  };

  return (
    <li
      className={`primary-tabs__item ${
        kind === containedKind ? "is-active" : ""
      }`}
    >
      <button
        className="primary-tabs__item__link"
        aria-expanded="false"
        data-href="details-chart-0__chart"
        onClick={(event) => handleClick(event)}
      >
        {containedKind}
      </button>
    </li>
  );
}

export default ObservationKindButton;

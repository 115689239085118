import "./App.css";
import "./assets/styles/all.scss";
import React, { useEffect, useReducer } from "react";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";

import AppInner from "./components/AppInner";

import apiReducer from "./api/apiReducer";
import useProcessResponse from "./api/useProcessResponse";
import useDataFromApi from "./api/useDataFromApi";

import kinds from "./utils/enums/kinds";
import locations from "./utils/enums/locations";
import { kebabize } from "./utils/string-utils";

function App() {
  const navigate = useNavigate();

  const navigatePage = ({ kind, location }) => {
    navigate(`/${kebabize(location)}/${kebabize(kind)}`);
  };

  const { pathname, hash } = useLocation();
  let locationInitialState;
  let kindInitialState;
  let disallowKindsInitial;
  let page404 = false;
  let isIframe = false;

  const pathnameParts = pathname.split("/");
  let [hashLocation, hashKind] = [pathnameParts[1], pathnameParts[2]];
  if (pathnameParts.length !== 3) {
    [hashLocation, hashKind] = ["lobith", "waterhoogte"];
  }
  if (hash === "#iframe")
  { 
    isIframe = true;
  }

  if (hashLocation === "" || hashLocation === "lobith") {
    locationInitialState = locations.Lobith;
    if (hashKind === "waterhoogte") {
      kindInitialState = kinds.Waterhoogte;
    } else if (hashKind === "waterafvoer") {
      kindInitialState = kinds.Waterafvoer;
    } else {
      page404 = true;
    }
    disallowKindsInitial = [];
  } else if (hashLocation === "sint-pieter" && hashKind === "waterafvoer") {
    locationInitialState = locations.SintPieter;
    kindInitialState = kinds.Waterafvoer;
    disallowKindsInitial=[kinds.Waterhoogte];
  } else {
    page404 = true;
  }

  const initialState = {
    status: "idle",
    location: locationInitialState,
    kind: kindInitialState,
    data: [],
    insluitenDelenActive: false,
    linkPopupActive: false,
    embedPopupActive: false,
    isGrenswaardenActive: false,
    error: null,
    disallowKinds: disallowKindsInitial,
    isIframe: isIframe
  };

  const [state, dispatch] = useReducer(apiReducer, initialState);

  const processResponse = useProcessResponse(dispatch);

  const getDataFromApi = useDataFromApi(dispatch, processResponse);

  useEffect(() => {
    !page404 && getDataFromApi(locationInitialState, kindInitialState); // Yes, only on initial load
    
    return () => {};
  }, [getDataFromApi, page404, locationInitialState, kindInitialState]);

  return (
    <>{AppInner({ page404, state, getDataFromApi, dispatch, navigatePage })}</>
  );
}

export default App;

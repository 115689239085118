
import React from 'react'

/**
 * A small optimization on createContext that comes from here:
 * https://juliangaramendy.dev/blog/strict-react-context
 * 
 */
export default function createStrictContext(options = {}) {
  const Context = React.createContext(undefined)
  Context.displayName = options.name // for DevTools

  function useContext() {
    const context = React.useContext(Context)
    if (context === undefined) {
      throw new Error(
        options.errorMessage || `${options.name || ''} Context Provider is missing`
      )
    }
    return context
  }

  return [Context.Provider, useContext]
}

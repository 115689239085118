import React from "react";

function LinkSvg({ svgClass, ariaHidden, id }) {
  return (
    <svg
      className={svgClass}
      id={id ?? "link"}
      viewBox="0 0 13 13"
      aria-hidden={ariaHidden ?? "true"}
    >
      <path
        d="M11.36 9.466a.73.73 0 0 0-.222-.536l-1.64-1.64a.73.73 0 0 0-.537-.222.75.75 0 0 0-.565.252l.15.146c.084.082.14.138.17.17.03.03.068.08.118.15a.634.634 0 0 1 .13.418.73.73 0 0 1-.22.536.73.73 0 0 1-.536.22.634.634 0 0 1-.418-.13 1.878 1.878 0 0 1-.15-.117 4.88 4.88 0 0 1-.17-.17c-.08-.084-.13-.134-.147-.15a.765.765 0 0 0-.26.576c0 .21.074.386.22.533l1.626 1.633c.14.142.32.213.535.213a.76.76 0 0 0 .537-.205l1.16-1.15a.72.72 0 0 0 .22-.53zm-5.546-5.56a.73.73 0 0 0-.22-.538L3.967 1.735a.73.73 0 0 0-.537-.22.762.762 0 0 0-.535.213l-1.16 1.15a.72.72 0 0 0-.22.53c0 .21.073.39.22.536l1.64 1.64a.73.73 0 0 0 .538.214c.22 0 .41-.082.568-.245a21.683 21.683 0 0 0-.15-.146 4.88 4.88 0 0 1-.17-.17 1.878 1.878 0 0 1-.115-.15.634.634 0 0 1-.13-.417c0-.21.073-.39.22-.537a.73.73 0 0 1 .537-.22.634.634 0 0 1 .42.13c.067.05.117.09.15.118l.17.17c.08.087.13.137.144.15a.765.765 0 0 0 .26-.573zm7.06 5.56c0 .63-.224 1.165-.67 1.6l-1.16 1.153a2.18 2.18 0 0 1-1.602.652 2.16 2.16 0 0 1-1.61-.67L6.21 10.57a2.178 2.178 0 0 1-.655-1.6c0-.648.232-1.197.695-1.65l-.695-.694a2.21 2.21 0 0 1-1.64.694 2.19 2.19 0 0 1-1.61-.662l-1.64-1.64A2.19 2.19 0 0 1 0 3.408c0-.63.224-1.166.67-1.603L1.83.655A2.17 2.17 0 0 1 3.43 0a2.16 2.16 0 0 1 1.61.67l1.626 1.633c.436.437.654.97.654 1.602a2.22 2.22 0 0 1-.694 1.648l.694.695a2.21 2.21 0 0 1 1.64-.695c.632 0 1.17.22 1.61.663l1.64 1.64c.443.443.664.98.664 1.61z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}

export default LinkSvg;

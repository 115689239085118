import { colorRgbaToHex } from "../../../../utils/color-utils";

const orders = {
  waterhoogteOrder: [
    "Waterhoogte verwacht",
    "Modelverwachting mediaan",
    "Overeengekomen lage rivierstand (< 739cm)",
    "Mediaan meerjarig",
    "Licht verhoogd (>1200cm)",
    "Verhoogd (1300 - 1500cm)",
    "Hoogwater (> 1500cm)",
    "Extreem hoogwater (> 1650cm)",
    "Verlaagde waterstand (< 815cm)",
    "Normaal (> 815cm)",
    "33-66% band",
    "10-90% band",
    "Gemeten waterhoogte",
  ],
  waterafvoerOrder: [
    "Afvoer verwacht",
    "Modelverwachting mediaan",
    "Verlaagd (<1400m3/s)",
    "Normaal (>1400 m3/s)",
    "Mediaan meerjarig",
    "Licht verhoogd (>4400 m3/s)",
    "Verhoogd (>5400m3/s)",
    "Hoge afvoer (>8100 m3/s)",
    "Extreme afvoer (>11800 m3/s)",
    "10-90% band",
    "33-66% band",
    "Gemeten afvoer",
  ],
};

export default function getOrderedLegendItems(totalSeries, limits, kind) {

  const unsortedLegendItems = [];

  limits.forEach((limit) => {
    unsortedLegendItems.push({
      ...limit, 
      color: colorRgbaToHex(
        limit.colorInRgba.red,
        limit.colorInRgba.green,
        limit.colorInRgba.blue,
        limit.colorInRgba.opacity
      ),
      softColor: colorRgbaToHex(
        limit.softColorInRgba.red,
        limit.softColorInRgba.green,
        limit.softColorInRgba.blue,
        limit.softColorInRgba.opacity
      ),
      isLimit: true,
    });
  });

  totalSeries.forEach((serie) => {
    unsortedLegendItems.push({
      label: serie.name,
      color: serie.color,
      isLimit: serie.isLimit,
    });
  });

  let order = [];
  if (kind === "waterhoogte") {
    order = orders.waterhoogteOrder;
  } else {
    order = orders.waterafvoerOrder;
  }

  return unsortedLegendItems.sort(
    (legendItemA, legendItemB) =>
      order.indexOf(legendItemA.label) - order.indexOf(legendItemB.label)
  );
}

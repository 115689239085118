import React from "react";

function EmbedSvg({ svgClass, ariaHidden, id }) {
  return (
    <svg
      className={svgClass}
      id={id ?? "embed"}
      aria-hidden={ariaHidden ?? "true"}
      viewBox="0 0 14 11"
    >
      <path
        d="M4.465 8.85l-.373.373a.236.236 0 0 1-.344 0l-3.48-3.48a.236.236 0 0 1 0-.343l3.48-3.48a.236.236 0 0 1 .344 0l.373.374a.236.236 0 0 1 0 .343L1.53 5.572l2.935 2.934a.236.236 0 0 1 0 .344zM8.878.883l-2.785 9.64a.243.243 0 0 1-.116.145.208.208 0 0 1-.175.018l-.463-.127a.243.243 0 0 1-.15-.118.224.224 0 0 1-.02-.183L7.96.62a.243.243 0 0 1 .115-.144.208.208 0 0 1 .176-.02l.466.128A.24.24 0 0 1 8.86.7a.224.224 0 0 1 .018.183zm4.906 4.86l-3.48 3.48a.236.236 0 0 1-.343 0l-.37-.373a.236.236 0 0 1 0-.344l2.935-2.934L9.59 2.637a.236.236 0 0 1 0-.343l.374-.374a.236.236 0 0 1 .344 0l3.48 3.48a.236.236 0 0 1 0 .343z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}

export default EmbedSvg;

import React from "react";

function ErrorMessage() {
  return (
    <div className="chart-error" role="alert" aria-live="assertive">
      <p className="chart-error__body">
        Grafiek kon niet worden geladen, data niet beschikbaar.
      </p>
      <button
        className="chart-error__retry js-details__actions__refresh"
        type="button"
      >
        Opnieuw proberen
      </button>
    </div>
  );
}

export default ErrorMessage;

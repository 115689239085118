import React from "react";
import ActionsTab from "./ActionsTab/ActionsTab";
import LinkPopup from "./ActionsTab/Popups/LinkPopup";
import EmbedPopup from "./ActionsTab/Popups/EmbedPopup";
import ObservationKindTab from "./ObservationKindTab/ObservationKindTab";

function ChartTopButtons() {
  return (
    <>
      <div className="details__actions js-details__actions">
        <ObservationKindTab />
        <ActionsTab/>
      </div>
      <LinkPopup />
      <EmbedPopup />
    </>
  );
}

export default ChartTopButtons;

import React from "react";
import ChartTopButtons from "./ChartTopButtons/ChartTopButtons";
import Chart from "./Chart/Chart";
import Legend from "./Legend/Legend";
import preprocessSeries, { sortTotalSeries, filterTotalSeriesDataHistory, parseHighLowSeries } from "./seriesAndHighSeries";
import { useAppContext } from "../../AppInner";

function ChartArea() {
  const {data, kind} = useAppContext();
  preprocessSeries(data.series);

  const newHighLowSeries = parseHighLowSeries(data.highLowSeries);
  let totalSeries = data.series.concat(newHighLowSeries);

  totalSeries = sortTotalSeries(totalSeries, kind);
  filterTotalSeriesDataHistory(totalSeries);

  const { limits, t0 } = data;
  return (
    <>
      <ChartTopButtons />
      <div id="details-chart-0__chart" className="details-chart-0__chart">
        <Chart limits={limits} totalSeries={totalSeries} t0={t0} />
        <Legend limits={limits} totalSeries={totalSeries} kind={kind} />
      </div>
    </>
  );
}

export default ChartArea;

import React from "react";
import CircledCrossSvg from "../../../../../../assets/SVGs/CircledCrossSvg"

function CommonPopupBase({clipboardText, onCloseHandler}) {
  return (
    <>
      <div className="chart-share__input">
        <input
          readOnly
          className="chart-share__input__field js-details__share__input"
          type="text"
          value={clipboardText}
          id="details-chart-0__share-link__input"
        />
      </div>
      <div className="chart-share__copy">
        <button
          className="chart-share__copy__button js-details__share__copy"
          data-clipboard-text={clipboardText}
          type="button"
          onClick={(ev) => {navigator.clipboard.writeText(ev.target.getAttribute("data-clipboard-text"))}}
        >
          kopiëren
        </button>
      </div>
      <div className="chart-share__close">
        <button
          className="btn--close js-details__share__close"
          tabIndex="0"
          type="button"
          onClick={onCloseHandler}
        >
          <span className="accessibility">Meetpunten in de buurt sluiten</span>
          <CircledCrossSvg svgClass={"btn--close__icon"} />
        </button>
      </div>
    </>
  );
}

export default CommonPopupBase;

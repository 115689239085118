import React from "react";
import ExportSvg from "../../../../../../assets/SVGs/ExportSvg";
import EmbedSvg from "../../../../../../assets/SVGs/EmbedSvg";
import LinkSvg from "../../../../../../assets/SVGs/LinkSvg";
import { useAppContext } from "../../../../../AppInner";
import actionTypes from "../../../../../../utils/enums/actionTypes";

function InsluitenDelen() {
  const {dispatch, kind, location, insluitenDelenActive, linkPopupActive, embedPopupActive} = useAppContext();

  const toggleInsluitenDelen = () => {
    dispatch({type: actionTypes.OPEN_CLOSE_TABS, payload: { kind, location, insluitenDelenActive: !insluitenDelenActive }})
  }

  const LinkPopUpOpenen = () => {
    dispatch({type: actionTypes.OPEN_CLOSE_TABS, payload: { kind, location, insluitenDelenActive: !insluitenDelenActive }})
    dispatch({type: actionTypes.OPEN_CLOSE_LINK_POPUP, payload: { kind, location, linkPopupActive: !linkPopupActive }})
  }

  const EmbedPopUpOpenen = () => {
    dispatch({type: actionTypes.OPEN_CLOSE_TABS, payload: { kind, location, insluitenDelenActive: !insluitenDelenActive }})
    dispatch({type: actionTypes.OPEN_CLOSE_EMBED_POPUP, payload: { kind, location, embedPopupActive: !embedPopupActive}})
  }

  return (
    <li className="chart-actions__item chart-actions__export js-details__actions__export">
      <button
        className={`chart-actions__item__link js-details__actions__export__button ${insluitenDelenActive && "is-active"}`}
        type="button"
        tabIndex="0"
        aria-expanded={insluitenDelenActive}
        aria-label={insluitenDelenActive? "inklappen" : "uitklappen"}
        onClick={toggleInsluitenDelen}
      >
        <ExportSvg svgClass={"chart-actions__item__link__icon"} />

        <span className="chart-actions__item__link__label">
          Insluiten/Delen
        </span>
      </button>
      <ul className={`chart-actions__dropdown js-details__actions__export__dropdown ${insluitenDelenActive && "is-open"}`}>
        <li className="chart-actions__dropdown__item">
          <button
            className="chart-actions__dropdown__item__link js-focus-start js-details__actions__share js-details__actions__share--embed"
            type="button"
            tabIndex="0"
            data-type="share-embed"
            onClick={EmbedPopUpOpenen}
          >
            <EmbedSvg svgClass={"chart-actions__dropdown__item__link__icon"} />
            <span className="chart-actions__dropdown__item__link__label">
              Insluiten
            </span>
          </button>
        </li>
        <li className="chart-actions__dropdown__item">
          <button
            className="chart-actions__dropdown__item__link js-details__actions__share js-details__actions__share--link js-focus-trap"
            type="button"
            tabIndex="0"
            data-type="share-link"
            onClick={LinkPopUpOpenen}
          >
            <LinkSvg svgClass={"chart-actions__dropdown__item__link__icon"} />
            <span className="chart-actions__dropdown__item__link__label">
              Link
            </span>
          </button>
        </li>
      </ul>
    </li>
  );
}

export default InsluitenDelen;

import { useCallback } from "react";
import axios from "axios";
import moment from "moment";
import actionTypes from "../utils/enums/actionTypes";
import locations from '../utils/enums/locations'

export default function useDataFromApi(dispatch, processResponse) {
  return useCallback(
    async (location, kind, start = true) => {
      if (start) {
        dispatch({
          type: actionTypes.START,
          payload: { location: location, kind: kind },
        });
      }

      const [ urlLocation, urlKind ] = [location, kind].map(x => encodeURIComponent(x.trim().toLowerCase()));
      
      const dagenVooruit = location === locations.Lobith ? 13 : 5; // subtract 1 
      const startDateMoment = process.env.REACT_APP_FAKE_SERVER_FIXED_START_DATE != null ? moment(process.env.REACT_APP_FAKE_SERVER_FIXED_START_DATE) : moment();
      const startDate = moment(startDateMoment).add(-2, "d").utc().format();
      const endDate = moment(startDateMoment).add(dagenVooruit, "d").utc().format();
      const url = `${process.env.REACT_APP_API_ENDPOINT}?location=${urlLocation}&unit=${urlKind}&startDate=${startDate}&endDate=${endDate}`;

      if (document.querySelector(".spinner")) {
        document.querySelector(".details__chart__chart").style.opacity = "0.4";
        document.querySelector(".spinner").style.zIndex = "90";
        document.querySelector(".spinner").style.display = "block";
      }

      await axios(url)
        .then((res) => {
          if (document.querySelector(".spinner")) {
            document.querySelector(".details__chart__chart").style.opacity = "1";
            document.querySelector(".spinner").style.zIndex = "-90";
            document.querySelector(".spinner").style.display = "none";
          }
          if (start) {
            dispatch({
              type: actionTypes.SUCCESS,
              payload: { location: location, kind: kind, data: res.data },
            });
          }
          processResponse(res.data, location, kind, start);
        })
        .catch((err) => {
          if (start) {
            dispatch({ type: actionTypes.ERROR, payload: { error: err.message } });
          }
        });
    },
    [processResponse, dispatch]
  );
}

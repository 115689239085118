import React from "react";
import RefreshSvg from "../../../../../../assets/SVGs/RefreshSvg"
import { useAppContext } from "../../../../../AppInner";
import actionTypes from "../../../../../../utils/enums/actionTypes";

function Refresh() {
  const {dispatch, location, kind} = useAppContext();

  return (
    <li className="chart-actions__item chart-actions__refresh">
      <button
        className="chart-actions__item__link js-details__actions__refresh__button"
        type="button"
        tabIndex="0"
        onClick={() => dispatch({type: actionTypes.REFRESH_CHART, payload: { kind: kind, location: location}})}
      >
        <RefreshSvg svgClass={"chart-actions__item__link__icon"} />
        <span className="chart-actions__item__link__label">Refresh</span>
        <span className="accessibility js-details__actions_refresh__accessibility--label">
          Ververs opties uitklappen
        </span>
      </button>
    </li>
  );
}

export default Refresh;

import React from "react";

function LocationSvg({ svgClass, ariaHidden, id }) {
  return (
    <svg
      className={svgClass}
      id={id ?? "location"}
      viewBox="0 0 16 16"
      aria-hidden={ariaHidden ?? "true"}
    >
      <path
        d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 14c-3.59 0-6-2.41-6-6s2.41-6 6-6 6 2.41 6 6-2.41 6-6 6zm1.5-7.5L5 4H4v1l2.5 4.5L11 12h1v-1L9.5 6.5zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}
export default LocationSvg;
